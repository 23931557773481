// src/App.tsx
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';

const App: React.FC = () => {
  const { instance, accounts } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch(e => {
      console.error(e);
    });
  };

  const handleLogout = () => {
    console.log(accounts)
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  };

  return (
    <div>
      <h1>Welcome to My App</h1>
      {accounts.length > 0 ? (
        <button onClick={handleLogout}>Logout</button>
      ) : (
        <button onClick={handleLogin}>Login</button>
      )}
      
    </div>
  );
};

export default App;